import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import BaseTile from '../BaseTile/BaseTile';
import Text from '../../atoms/Text/Text';
import Link from '../../atoms/Link/Link';
import {
  arrowSymbol,
  colors,
  fontSizes,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import sanityImage from '../../../utils/sanityImage';
import { truncateTextByWords } from '../../../utils/textUtils';

const descriptionCss = css`
  margin: ${uc('10px 0 18px')};
  line-height: ${lineHeights.title};
`;

const customCss = css`
  .title {
    font-size: ${uc('18px')};
    line-height: ${uc('22px')};
  }

  .subtitle {
    margin: 0;
    color: ${colors.mediumGray};
    font-size: ${uc('14px')};
  }

  .image-wrapper {
    height: ${uc('120px')};
  }

  .container {
    height: ${uc('395px')};

    &:hover {
      .title {
        &::after {
          content: ${arrowSymbol};
          margin-left: ${uc('3px')};
        }
      }
    }
  }
`;

const MarketplacePartnerTile = ({ partner }) => {
  if (!partner) {
    return null;
  }

  // shorten description length if the tile has a very long title
  const descriptionWordMax =
    truncateTextByWords(partner.title, 6).length > 34 ? 20 : 25;

  return (
    <div data-testid="partner-tile">
      <Link to={partner.link}>
        <BaseTile
          customCss={customCss}
          title={truncateTextByWords(partner.title, 6)}
          subtitle={
            partner.isIntegratedPartner ? 'INTEGRATED PARTNER' : 'PARTNER'
          }
          imageUrl={sanityImage(partner.logo)
            .auto('format')
            .maxHeight(120)
            .url()}
        >
          <Text css={descriptionCss} fontSize={fontSizes.sixteen}>
            {truncateTextByWords(partner.description, descriptionWordMax)}
          </Text>
        </BaseTile>
      </Link>
    </div>
  );
};

MarketplacePartnerTile.propTypes = {
  partner: PropTypes.instanceOf(Object).isRequired,
};

export default MarketplacePartnerTile;
